// require("dotenv").config();

module.exports = {
  urlLightningConfig: process.env.REACT_APP_URL_LIGHTNING_CONFIG || "https://kumwelllws.ubonmicrotech.com",
  urlAuth: process.env.REACT_APP_URL_AUTH || "https://kumwelllws.ubonmicrotech.com",
  urlSettingNotification: process.env.REACT_APP_URL_SETTING_NOTIFICATION || "https://kumwelllws.ubonmicrotech.com",
  urlNotification: process.env.REACT_APP_URL_NOTIFICATION || "https://kumwelllws.ubonmicrotech.com",
  urlWebsocket: process.env.REACT_APP_URL_WEBSOCKET || "wss://https://kumwelllws.ubonmicrotech.com",
  urlSound: process.env.REACT_APP_URL_SOUND || "https://kumwelllws.ubonmicrotech.com",
  urlDevice: process.env.REACT_APP_URL_DEVICE || "https://kumwelllws.ubonmicrotech.com",
  urlTestWarning: process.env.REACT_APP_URL_TEST_WARNING || "https://kumwelllws.ubonmicrotech.com/",
};
