import React, { useContext, useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Circle, useMap, CircleMarker, Popup } from "react-leaflet";
import { FormControl, InputLabel, MenuItem, Select, IconButton, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { v4 as uuidv4 } from "uuid";
// import { LatLngBounds } from "leaflet";
import { useNavigate } from "react-router-dom";
import { showSidebar } from "../../kumwell";
import ChartEField2 from "./ChartEField2.jsx";
import apiUrls from "../../ApiUrl";
import Cookies from "js-cookie";
import moment from "moment";
import "./MapView.css";
import "../Routers.css";
import L from "leaflet";

const config = require("./../../configs/app");

export default function MapView({ audioAPI }) {
  const [showEfield, setShowEfield] = useState(false);
  const { auth, Organization_location, soundWarning, setSoundWarning, setOrganization_Setting, soundUrl } =
    useContext(showSidebar);
  const [zoomLevel, setZoomLevel] = useState(13);
  const [reload, setreload] = useState(0);
  const [weatherData, setWeatherData] = useState(null);
  const [pm25, setPm25] = useState(null);
  const [EfieldLocation, setEfieldLocation] = useState("");
  const [Efield, setEfield] = useState("");
  const [EfieldMarker, setEfieldMarker] = useState("");
  const [showLighning, setShowLighning] = useState(false);
  const [MfieldWarning, setMFieldWarning] = useState([]);
  const [mapCenter, setMapCenter] = useState([]);
  const [AllEfield_admin, setAllEfield_admin] = useState("");
  const { showSidebars } = useContext(showSidebar);
  const navigate = useNavigate();
  const socketRef = useRef(null);
  const [warningData, setWarningData] = useState([]);
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [alertData, setAlertData] = useState("");
  const [alertDataUse, setAlertDataUse] = useState("");
  const token = Cookies.get("accessToken");

  const mapRef = useRef(null);
  const settinOrganization = (keyname) => {
    Cookies.set("pathName", keyname);
    setOrganization_Setting(keyname);
    navigate(`/organization/setting-organization/${keyname}`);
  };

  const handleSoundMute = () => {
    setSoundWarning(!soundWarning);
  };

  const [selectedLocation, setSelectedLocation] = useState("");

  const img1sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_RE.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img2sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_OR.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img3sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_GR.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img1Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CCR.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img2Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CR.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img3Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CCO.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img4Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CO.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img5Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CCY.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img6Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "./image/Lightning_icon/K_ICON_CY.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  // const asiaBounds = new LatLngBounds([0, 70], [60, 140]);

  const matchingPermissions = auth.Role.Permissions.filter((item) => item.name === "Home").map(
    (permission) => permission
  );

  // const MapWrapper = () => {
  //   const map = useMap();
  //   useEffect(() => {
  //     const handleZoomEnd = () => {
  //       setZoomLevel(map.getZoom());
  //     };
  //     map.on("zoomend", handleZoomEnd);
  //     return () => {
  //       map.off("zoomend", handleZoomEnd);
  //     };
  //   }, [map]);
  //   return null;
  // };

  useEffect(() => {
    if (matchingPermissions.find((item) => item.type === "update")) {
      fetch(`${config.urlLightningConfig}/api/v1/e-field-sensor/`)
        .then((res) => res.json())
        .then((res) => setEfieldLocation(res))
        .catch((err) => console.error(`Error Fetch Role: ${err}`));
    }
  }, []);

  const connectWebSocket = () => {
    socketRef.current = new WebSocket(`${config.urlWebsocket}`);
    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setWarningData(data);
    };
    socketRef.current.onclose = () => {
      setTimeout(connectWebSocket, 1000);
    };
  };

  useEffect(() => {
    if (token && auth.Role.roleId !== 24 && auth.Role.roleId !== 29) {
      connectWebSocket();
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, []);

  useEffect(() => {
    const filteredDataWarning =
      warningData &&
      warningData[0] &&
      warningData.filter((item) => item.keyName === Organization_location.message.keyName);
    const highestLevelObject = filteredDataWarning
      ? filteredDataWarning.reduce((acc, obj) => {
          if (!acc || obj.level > acc.level) {
            return obj;
          }
          return acc;
        }, null)
      : null;
    const result = highestLevelObject ? [highestLevelObject] : [];
    let intervalId;
    if (warningData && result[0]) {
      setAlertData((prevAlerts) => {
        let updatedAlerts = [...prevAlerts, result[0]];
        let updatedData = updatedAlerts.map((item) => ({ ...item, time: 15 }));
        return updatedData;
      });
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [warningData]);

  // const closeAlertAfterDelay = (index, time) => {
  //   const timeoutId = setTimeout(() => {
  //     handleAlertClose(index);
  //   }, time);
  //   return timeoutId;

  useEffect(() => {
    if (alertData.length > 1) {
      setAlertData((prevAlerts) => {
        const updatedAlerts = [prevAlerts[prevAlerts.length - 1]];
        return updatedAlerts;
      });
    }
  }, [alertData]);

  // useEffect(() => {
  //   timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
  //   const newTimeoutIds = alertData.map((alert, index) => {
  //     if (alert.time) {
  //       return closeAlertAfterDelay(index, 1000 * alert.time);
  //     }
  //     return null;
  //   });
  //   setTimeoutIds(newTimeoutIds);
  //   return () => {
  //     newTimeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
  //   };
  // }, [alertData]);

  const handleAlertClose = (index) => {
    setAlertData((prevAlerts) => {
      const updatedAlerts = [...prevAlerts];
      updatedAlerts.splice(index, 1);
      return updatedAlerts;
    });
  };

  useEffect(() => {
    if (window.location.pathname !== "/HomePage" && token) {
      navigate("/HomePage");
    }
  }, [navigate]);

  const fetchWeatherData = () => {
    if (Organization_location.status === 200) {
      apiUrls.setWeatherLat(Organization_location.message.lat);
      apiUrls.setWeatherLon(Organization_location.message.lon);
      fetch(apiUrls.weather, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Error fetching weather data");
          }
        })
        .then((data) => {
          setWeatherData(data);
        })
        .catch((error) => {});
      return fetch(apiUrls.pm25, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setPm25(response);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (weatherData) {
      const intervalId = setInterval(fetchWeatherData, 60000 * 5);
      return () => {
        clearInterval(intervalId);
      };
    }
    fetchAllsensors();
  }, []);

  const fetchData = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/calculateMField`;
      const data = {
        lat: Organization_location.message.lat,
        lon: Organization_location.message.lon,
        radius: Organization_location.message.display,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.status === 200) {
        setMFieldWarning(result);
      }
      console.log(result);
      // setMFieldWarning(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAdmin = async () => {
    try {
      const response = await fetch(`${config.urlLightningConfig}/api/v1/m-field`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log(data)
      if (data.status === 200) {
        setMFieldWarning(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const fetchAllsensors = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/e-field-sensor/`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      setAllEfield_admin(result);
    } catch (error) {}
  };

  const getStatusAlert = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/state-alert/keyName/${Organization_location.message.keyName}`;
      // console.log(url)
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result)
      if (result.status === 200) {
        setAlertDataUse(result.message);
        // console.log("1234564898789798")
      }
      // console.log(result);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (alertData) {
      getStatusAlert();
    }
  }, [alertData]);

  const comfirmAlert = () => {
    const url = `${config.urlLightningConfig}/api/v1/state-alert/closePopup/${Organization_location.message.keyName}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        getStatusAlert();
        // console.log('Data posted successfully:', responseData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  // const comfirmAlert = () => {
  //   const url = `${config.urlLightningConfig}/api/v1/state-alert/closePopup/$${Organization_location.message.keyName}`;
  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((responseData) => {
  //       getStatusAlert();
  //       console.log('Data posted successfully:', responseData);
  //     })
  //     .catch((error) => {
  //       console.error('Error posting data:', error);
  //     });
  // }

  useEffect(() => {
    // if (Organization_location.status === 200 && auth.Role.roleId !== 24 && auth.Role.roleId !== 29) {
    //   fetchData();
    //   getStatusAlert();
    //   const intervalId = setInterval(fetchData, 60000);
    //   return () => clearInterval(intervalId);
    // } else if (Organization_location.status !== 200 && auth.Role.roleId === 24 && auth.Role.roleId === 29) {
    //   fetchDataAdmin();
    //   const intervalId = setInterval(fetchDataAdmin, 60000);
    // }
    if (auth.Role.roleId === 24 || auth.Role.roleId === 29) {
      fetchDataAdmin();
      const intervalId = setInterval(fetchDataAdmin, 60000);
      // return () => clearInterval(intervalId);
    } else if (Organization_location.status === 200 && auth.Role.roleId !== 24 && auth.Role.roleId !== 29) {
      fetchData();
      getStatusAlert();
      const intervalId = setInterval(fetchData, 60000);
      return () => clearInterval(intervalId);
    }
  }, [Organization_location.status]);

  if (
    Organization_location.status === 200 &&
    !matchingPermissions.find((item) => item.type === "update") &&
    matchingPermissions.find((item) => item.type === "read")
  ) {
    const center = [Organization_location.message.lat, Organization_location.message.lon];
    const typeUser = Organization_location.message.mapType;
    const theData = Organization_location.message;

    const setPosition = () => {
      const targetLocation = { lat: center[0], lon: center[1] };
      if (mapRef.current) {
        mapRef.current.setView(targetLocation, 11);
        setreload(1);
      }
    };
    if (reload === 0) {
      setPosition();
    }
    if (!Efield && Organization_location.message.EFieldSensors[0]) {
      setEfield(Organization_location.message.EFieldSensors[0].warningPointId);
    }
    if (!weatherData) {
      fetchWeatherData();
    }

    const currentTime = moment.utc();
    function isWithinLast15Minutes(date) {
      const diffInMilliseconds = currentTime.diff(moment.utc(date));
      return diffInMilliseconds <= 15 * 60 * 1000;
    }
    function getTimeRange(date) {
      const diffInMilliseconds = currentTime.diff(moment.utc(date));

      if (diffInMilliseconds <= 5 * 60 * 1000) {
        return "ZoneR";
      } else if (diffInMilliseconds <= 10 * 60 * 1000) {
        return "ZoneA";
      } else if (diffInMilliseconds <= 15 * 60 * 1000) {
        return "ZoneB";
      }
      return "ZoneC";
    }

    const filteredDataWithMarkers = Object.keys(MfieldWarning.message || {})
      .filter((key) => isWithinLast15Minutes(MfieldWarning.message[key].date))
      .map((key) => {
        const entry = MfieldWarning.message[key];
        entry.markerImage = getTimeRange(entry.date);
        return entry;
      });

    let nearestData = null;
    let nearestDistance = Infinity;
    for (const key in filteredDataWithMarkers) {
      if (filteredDataWithMarkers.hasOwnProperty(key)) {
        const point = filteredDataWithMarkers[key];
        const distance = calculateDistance(
          Organization_location.message.lat,
          Organization_location.message.lon,
          parseFloat(point.lat),
          parseFloat(point.lon)
        );
        if (distance < nearestDistance) {
          nearestDistance = distance;
          nearestData = point;
        }
      }
    }

    const activeZoneR = Organization_location.message.activeZoneR;
    const activeZoneA = Organization_location.message.activeZoneA;
    const activeZoneB = Organization_location.message.activeZoneB;
    const activeZoneEField = Organization_location.message.activeZoneEField;

    return (
      <React.Fragment>
        <div className={`mapView-container ${showSidebars}`}>
          <div className="route-map-backgorund">
            <MapContainer
              center={center}
              // maxBounds={asiaBounds}
              maxBoundsViscosity={1.0}
              ref={mapRef}
              zoomControl={false}
              maxZoom={18}
              minZoom={4}
              zoom={12}
              style={{ height: "100%", width: "100%" }}
            >
              {/* <MapWrapper /> */}
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
              />
              {filteredDataWithMarkers &&
                filteredDataWithMarkers.map((item, index) => {
                  if (!item.lat || !item.lon || isNaN(item.lat) || isNaN(item.lon)) {
                    console.warn("Invalid coordinates found in filteredDataWithMarkers:", item);
                    return null; // Skip rendering this marker
                  }
                  let markerIcon = "";
                  if (item.markerImage === "ZoneR") {
                    markerIcon = img1sIcon;
                  } else if (item.markerImage === "ZoneA") {
                    markerIcon = img2sIcon;
                  } else if (item.markerImage === "ZoneB") {
                    markerIcon = img3sIcon;
                  } else {
                    markerIcon = img1sIcon;
                  }
                  return (
                    <Marker key={index} position={[item.lat, item.lon]} icon={markerIcon}>
                      <Popup>
                        <div className="popup-Alert">
                          <p>Type: {item.type == 1 ? <>Cloud to Ground</> : <>Cloud to Cloud</>}</p>
                          <p>Current: {item.amp} kA</p>
                          <p>Date: {moment.utc(item.date).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                          <p>Latitude: {item.lat}</p>
                          <p>Longitude: {item.lon}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              {/* {filteredDataWithMarkers &&
                filteredDataWithMarkers.map((item, index) => {
                  // Add a check for valid lat/lon
                  if (!item.LAT || !item.LON || isNaN(item.LAT) || isNaN(item.LON)) {
                    console.warn("Invalid coordinates found in filteredDataWithMarkers:", item);
                    return null; // Skip rendering this marker
                  }
                  let markerIcon = "";
                  if (item.markerImage === "ZoneR") {
                    markerIcon = img1sIcon;
                  } else if (item.markerImage === "ZoneA") {
                    markerIcon = img2sIcon;
                  } else if (item.markerImage === "ZoneB") {
                    markerIcon = img3sIcon;
                  } else {
                    markerIcon = img1sIcon;
                  }
                  return (
                    <Marker key={index} position={[parseFloat(item.LAT), parseFloat(item.LON)]} icon={markerIcon}>
                      <Popup>
                        <div className="popup-Alert">
                          <p>Type: {item.type == 1 ? <>Cloud to Ground</> : <>Cloud to Cloud</>}</p>
                          <p>Current: {item.amp} kA</p>
                          <p>Date: {moment.utc(item.date).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                          <p>Latitude: {item.LAT}</p>
                          <p>Longitude: {item.LON}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })} */}
              {zoomLevel >= 7 && (
                <>
                  <CircleMarker center={center} radius={9} maxScale={9} color="white" fillColor="red" fillOpacity={0.8}>
                    <Popup className="">
                      <div className="popup-header">Organization</div>
                      <div className="popup-content">
                        <div className="popup-label">Organization Name : </div>
                        <div className="popup-value">{Organization_location.message.keyName}</div>
                      </div>
                      <div className="popup-content">
                        <div className="popup-label">Latitude : </div>
                        <div className="popup-value">{center[0]}</div>
                      </div>
                      <div className="popup-content">
                        <div className="popup-label">Longitude : </div>
                        <div className="popup-value">{center[1]}</div>
                      </div>
                    </Popup>
                    <Circle
                      center={center}
                      radius={activeZoneR}
                      fill={true}
                      fillOpacity={0.1}
                      pathOptions={{
                        fillColor: "none",
                        color: "red",
                        transition: "transform 1s ease-in-out",
                        fillColor: "#ff0000",
                      }}
                    >
                      <Popup className="">
                        <div className="popup-header">activeZoneR Distance</div>
                        <div className="popup-content">
                          <div className="popup-value">{activeZoneR / 1000} km</div>
                        </div>
                      </Popup>
                      <Circle
                        center={center}
                        radius={activeZoneA}
                        fill={true}
                        fillOpacity={0}
                        pathOptions={{
                          color: "rgb(155, 155, 155)",
                        }}
                      >
                        <Popup className="">
                          <div className="popup-header">activeZoneA Distance</div>
                          <div className="popup-content">
                            <div className="popup-value">{activeZoneA / 1000} km</div>
                          </div>
                        </Popup>
                        <Circle
                          center={center}
                          radius={activeZoneB}
                          fill={true}
                          fillOpacity={0}
                          pathOptions={{
                            color: "rgb(155, 155, 155)",
                          }}
                        >
                          <Popup className="">
                            <div className="popup-header">activeZoneB Distance</div>
                            <div className="popup-content">
                              <div className="popup-value">{activeZoneB / 1000} km</div>
                            </div>
                          </Popup>
                          <Circle
                            center={center}
                            radius={activeZoneEField}
                            fill={true}
                            fillOpacity={0}
                            pathOptions={{
                              color: "rgb(155, 155, 155)",
                            }}
                          >
                            <Popup className="">
                              <div className="popup-header">E-Field Distance</div>
                              <div className="popup-content">
                                <div className="popup-value">{activeZoneEField / 1000} km</div>
                              </div>
                            </Popup>
                          </Circle>
                        </Circle>
                      </Circle>
                    </Circle>
                  </CircleMarker>
                </>
              )}
              <div className="Detail-Weather" align="center">
                <span className="title-block">Weather</span>
                <div className="white-temp">
                  {weatherData && (
                    <img
                      src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
                      alt="Weather"
                      width="50px"
                    />
                  )}
                  {weatherData && <div className="info-temp">{(weatherData.main.temp - 273.15).toFixed(2)} °C </div>}
                  <div>
                    <div>
                      {" "}
                      {weatherData && (
                        <div className="info-temp3">
                          <img src="./image/location.png" alt="marker" width="10px" /> &nbsp;{weatherData.name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="white-temp2" align="center">
                  <div className="white-box1">
                    <div>
                      <img src="./image/Hu_test.png" alt="Humidity" width="25px" />
                    </div>
                    <div className="info2-detail">Humidity</div>
                    {weatherData && <div className="info-temp3">{weatherData.main.humidity}% </div>}
                  </div>

                  <div className="white-box2">
                    <img src="./image/Win_test.png" alt="wind" width="30px" />
                    <div className="info2-detail">Wind Speed</div>
                    {weatherData && <div className="info-temp3">{weatherData.wind.speed} m/s </div>}
                  </div>

                  <div className="white-box3">
                    <img src="./image/PM_test.png" alt="status" width="30px" />
                    <div className="info2-detail">PM 2.5</div>
                    {pm25 && (
                      <div className="info-temp3">
                        {pm25.list[0].components.pm2_5} ug/m<sup>3</sup>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {nearestDistance !== Infinity && (
                // <div className={`showDistance user ${Math.round(nearestDistance) <= (theData.activeZoneR / 1000) && "R"}
                //   ${Math.round(nearestDistance) <= theData.activeZoneA / 1000 && Math.round(nearestDistance) > (theData.activeZoneR / 1000) && "A"}
                //   ${Math.round(nearestDistance) < (theData.activeZoneB / 1000) && Math.round(nearestDistance) > theData.activeZoneA / 1000 && "B"}`}
                //   align="center">
                //   Lightning within {Math.round(nearestDistance)} kilometers
                // </div>

                <div className={`showDistance user`} align="center">
                  Lightning within {Math.round(nearestDistance)} km
                </div>
              )}

              {Organization_location.message.EFieldSensors && typeUser !== 1 && (
                <div className="item-chart">
                  <div className="Detail-block-chart" align="center">
                    <span className="title-block">
                      Electric Field (V/m) Warning Point Name: &nbsp;
                      <Select
                        value={Efield}
                        onChange={(e) => setEfield(e.target.value)}
                        style={{ height: "18px", fontSize: "12px", background: "white" }}
                      >
                        {Organization_location.message.EFieldSensors.map((item, index) => (
                          <MenuItem value={item.warningPointId} key={uuidv4()}>
                            {item.warningPointName ? item.warningPointName : ""}
                          </MenuItem>
                        ))}
                      </Select>
                    </span>
                    <div className="white-block-chart">
                      {/* <ChartEField dataOrg={Efield} /> */}
                      <ChartEField2 dataOrg={Efield} key={Math.random()} />
                    </div>
                  </div>
                </div>
              )}
            </MapContainer>

            <div className="sound-mute org" align="center">
              <button
                onClick={handleSoundMute}
                style={{ border: "none", borderRadius: "15px", width: "50px", height: "25px", cursor: "pointer" }}
              >
                {soundWarning ? (
                  <img src="./image/volume.png" alt="volume" width="22px" />
                ) : (
                  <img src="./image/mute.png" alt="volume" width="22px" />
                )}
              </button>

              <div className="Circle-info" align="center" style={{ zIndex: 1000 }}>
                <div className={`Circle-law  ${alertDataUse && alertDataUse.level === 3 && "ActiveZoneR"}`} />
                <div className={`Circle-law  ${alertDataUse && alertDataUse.level === 2 && "ActiveZoneA"}`}></div>
                <div className={`Circle-law  ${alertDataUse && alertDataUse.level === 1 && "ActiveZoneB"}`}></div>
                <div
                  className={`Circle-law  ${
                    alertDataUse ? alertDataUse.level === 0 && "UnActiveZone" : "UnActiveZone"
                  }`}
                ></div>
              </div>

              {/* <div className="Circle-info" align="center">
                <div
                  className={`Circle-law  ${nearestDistance !== Infinity &&
                    Math.round(nearestDistance) <= theData.activeZoneR / 1000 &&
                    Math.round(nearestDistance) < theData.activeZoneA / 1000 &&
                    "ActiveZoneR"
                    }`}
                />
                <div
                  className={`Circle-law   ${nearestDistance !== Infinity &&
                    Math.round(nearestDistance) <= theData.activeZoneA / 1000 &&
                    Math.round(nearestDistance) > theData.activeZoneR / 1000 &&
                    "ActiveZoneA"
                    }`}
                ></div>
                <div
                  className={`Circle-law  ${nearestDistance !== Infinity &&
                    Math.round(nearestDistance) <= theData.activeZoneB / 1000 &&
                    Math.round(nearestDistance) > theData.activeZoneA / 1000 &&
                    "ActiveZoneB"
                    }`}
                ></div>
                <div
                  className={`Circle-law ${Math.round(nearestDistance) > theData.activeZoneB / 1000 && "UnActiveZone"}`}
                ></div>
              </div> */}
            </div>
            {typeUser !== 2 && (
              <>
                {" "}
                <div className="Detail-block one" align="center">
                  <span className="title-block">Zone</span>
                  <div className="white-block" align="left">
                    <div style={{ color: "red" }}>
                      Zone: R 0-{(activeZoneR / 1000).toFixed(0)} km radius <br />
                    </div>
                    Zone: A {(activeZoneR / 1000).toFixed(0)}-{(activeZoneA / 1000).toFixed(0)} km radius <br />
                    Zone: B {(activeZoneA / 1000).toFixed(0)}-{(activeZoneB / 1000).toFixed(0)} km radius <br />
                  </div>
                </div>
                <div className="Detail-block two" align="center">
                  <span className="title-block">Historical Stroke</span>
                  <div className="white-block" align="center">
                    <div>
                      <img
                        src={process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_RE.png"}
                        alt="red"
                        width="15px"
                      />
                      &nbsp; 0-5 Minutes&nbsp;&nbsp;&nbsp;
                    </div>
                    <div>
                      <img src="./image/Lightning_icon/Tunder_OR.png" alt="red" width="15px" />
                      &nbsp; 5-10 Minutes&nbsp;&nbsp;
                    </div>
                    <div>
                      <img src="./image/Lightning_icon/Tunder_GR.png" alt="red" width="15px" />
                      &nbsp; 10-15 Minutes
                    </div>
                  </div>
                </div>
                {/* {alertDataUse.level && !alertDataUse.closePopup &&
                  <div
                    style={{
                      position: "absolute",
                      top: "210px",
                      right: "10px",
                      width: "320px",
                      zIndex: 1100,
                    }}
                  >
                    <div
                      className={`AlertAdmin ${alertDataUse.level == 1 && "B"} ${alertDataUse.level == 2 && "A"
                        } ${alertDataUse.level == 3 && "R"} ${alertDataUse.level == 0 && "Safe"}`}
                      align="center"
                    >
                      <img src={process.env.PUBLIC_URL + "/image/warning.png"} alt="alert" width="35px" />
                      <div style={{ fontWeight: "bold" }}> WARNING LIGHTNING</div>
                      <div
                        style={{
                          marginBottom: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {(alertDataUse.level === 3 && (
                          <>
                            Now have a lightning discharge within a radius of less than {theData.activeZoneR / 1000}{" "}
                            kilometers.
                          </>
                        ))
                          || (alertDataUse.level === 2 && "Now have a lightning discharge level 2.")
                          || (alertDataUse.level === 1 && "Now have a lightning discharge level 1.")
                          || (alertDataUse.level === 0 && "The situation is safe.")
                        }

                      </div>
                      {audioAPI && (
                        <audio autoPlay loop>
                          <source src={soundUrl} type="audio/wav" />
                        </audio>
                      )}
                      <button className="close-btn-alert" onClick={comfirmAlert}>
                        CLOSE
                      </button>
                    </div>
                  </div>
                } */}
                {alertDataUse && (
                  <>
                    {!alertDataUse.closePopup && (
                      <div
                        style={{
                          position: "absolute",
                          top: "210px",
                          right: "10px",
                          width: "320px",
                          zIndex: 1100,
                        }}
                      >
                        <div
                          className={`AlertAdmin ${alertDataUse.level == 1 && "B"} ${alertDataUse.level == 2 && "A"} ${
                            alertDataUse.level == 3 && "R"
                          } ${alertDataUse.level == 0 && "Safe"}`}
                          align="center"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `${alertDataUse.level !== 0 ? "/image/warning.png" : "/image/shield.png"}`
                            }
                            alt="alert"
                            width="35px"
                          />
                          <div style={{ fontWeight: "bold" }}>{alertDataUse.level !== 0 && "WARNING LIGHTNING"}</div>
                          <div
                            style={{
                              marginBottom: "10px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            {(alertDataUse.level === 3 && (
                              <>
                                Now have a lightning discharge within a radius of less than {theData.activeZoneR / 1000}{" "}
                                km
                              </>
                            )) ||
                              (alertDataUse.level === 2 && "Now have a lightning discharge level 2.") ||
                              (alertDataUse.level === 1 && "Now have a lightning discharge level 1.") ||
                              (alertDataUse.level === 0 && "Now the situation is safe.")}
                          </div>
                          <button className="close-btn-alert" onClick={comfirmAlert}>
                            CLOSE
                          </button>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
                {audioAPI && alertDataUse.level !== 0 && (
                  <audio autoPlay loop>
                    <source src={soundUrl} type="audio/wav" />
                  </audio>
                )}
                {/* {alertData[0] && (
                  <div
                    style={{
                      position: "absolute",
                      top: "210px",
                      right: "10px",
                      width: "320px",
                      zIndex: 1100,
                    }}
                  >
                    <div
                      className={`AlertAdmin ${alertData[0].levelWarning == 1 && "B"} ${alertData[0].levelWarning == 2 && "A"
                        } ${alertData[0].levelWarning == 3 && "R"}`}
                      align="center"
                    >
                      <img src={process.env.PUBLIC_URL + "/image/warning.png"} alt="alert" width="35px" />
                      <div style={{ fontWeight: "bold" }}> WARNING LIGHTNING</div>
                      <div
                        style={{
                          marginBottom: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {(alertData[0].levelWarning === 3 && (
                          <>
                            Now have a lightning discharge within a radius of less than{" "}
                            {Organization_location.message.activeZoneR / 1000} kilometers.
                          </>
                        )) ||
                          (alertData[0].levelWarning === 2 && "Now have a lightning discharge level 2.") ||
                          (alertData[0].levelWarning === 1 && "Now have a lightning discharge level 1.")}
                      </div>
                      {audioAPI && (
                        <audio autoPlay loop>
                          <source src={soundUrl} type="audio/wav" />
                        </audio>
                      )}
                      <button className="close-btn-alert" onClick={handleAlertClose}>
                        CLOSE
                      </button>
                    </div>
                  </div>
                )} */}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else if (
    Organization_location.status === 200 &&
    matchingPermissions.find((item) => item.type === "update") !== undefined &&
    matchingPermissions.find((item) => item.type === "read")
  ) {
    const AllOrganization = Organization_location.message.map((item) => {
      const lat = item.lat;
      const lon = item.lon;
      const id = item.keyName;
      const activeZoneEField = item.activeZoneEField;
      const activeZoneR = item.activeZoneR;
      const activeZoneA = item.activeZoneA;
      const activeZoneB = item.activeZoneB;
      const nameLocation = item.nameLocation;
      return [lat, lon, id, activeZoneEField, activeZoneR, activeZoneA, activeZoneB, nameLocation];
    });

    const MainLocation = Organization_location.message.find((item) => item.keyName === auth.keyName);
    if (!mapCenter && AllOrganization) {
      setMapCenter([MainLocation.lat, MainLocation.lon]);
    }
    const center = [MainLocation.lat, MainLocation.lon];
    const setPosition = () => {
      const targetLocation = { lat: center[0], lon: center[1] };
      if (mapRef.current) {
        mapRef.current.setView(targetLocation, 6);
        setreload(1);
      }
    };

    const AllSensors = [];
    const GetEFieldAll = Organization_location.message.map((item) => item.EFieldSensors);
    for (const key in GetEFieldAll) {
      if (GetEFieldAll.hasOwnProperty(key)) {
        AllSensors.push(...GetEFieldAll[key]);
      }
    }
    if (!Efield && AllSensors) {
      setEfield(AllSensors[0].warningPointId);
    }

    const handleLocationChange = (event) => {
      const selectedLocationName = event.target.value;
      const selectedLocation = Organization_location.message.find((item) => item.nameLocation === selectedLocationName);
      if (selectedLocation) {
        const { lat, lon } = selectedLocation;
        mapRef.current.setView([lat, lon], 13);
      }
      setSelectedLocation(selectedLocation);
    };

    const handleLocationChangeEfield = () => {
      const EfieldLatLon = EfieldLocation.message.find((item) => item.warningPointId === Efield);
      setEfieldMarker(EfieldLatLon);
      if (EfieldLatLon) {
        const { lat, lon } = EfieldLatLon;
        mapRef.current.setView([lat, lon], 13);
      }
    };

    const handleClear = () => {
      setSelectedLocation("");
    };

    if (reload === 0) {
      setPosition();
    }

    const currentTime = moment.utc();
    function isWithinLast15Minutes(date) {
      const diffInMilliseconds = currentTime.diff(moment.utc(date));
      return diffInMilliseconds <= 15 * 60 * 1000;
    }

    function getTimeRange(date) {
      const diffInMilliseconds = currentTime.diff(moment.utc(date));
      if (diffInMilliseconds <= 5 * 60 * 1000) {
        return "ZoneR";
      } else if (diffInMilliseconds <= 10 * 60 * 1000) {
        return "ZoneA";
      } else if (diffInMilliseconds <= 15 * 60 * 1000) {
        return "ZoneB";
      }
      return "ZoneR";
    }

    const filteredDataWithMarkers = Object.keys(MfieldWarning.message || {})
      .filter((key) => isWithinLast15Minutes(MfieldWarning.message[key].DAT))
      .map((key) => {
        const entry = MfieldWarning.message[key];
        entry.markerImage = getTimeRange(entry.DAT);
        return entry;
      });

    return (
      <div className={`mapView-container ${showSidebars}`}>
        <div className="route-map-backgorund ">
          <MapContainer
            ref={mapRef}
            center={center}
            zoomControl={false}
            zoom={6}
            // maxBounds={asiaBounds}
            maxBoundsViscosity={1.0}
            maxZoom={18}
            minZoom={4}
            style={{ height: "100%", width: "100%" }}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
          >
            {/* <MapWrapper /> */}
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
            />

            {/* {selectedLocation && (
              <Marker position={[selectedLocation.lat, selectedLocation.lng]}>
                <Popup>{selectedLocation.name}</Popup>
              </Marker>
            )} */}

            {filteredDataWithMarkers &&
              showLighning &&
              filteredDataWithMarkers.map((item, index) => {
                let markerIcon = "";
                if (item.markerImage === "ZoneR") {
                  markerIcon = img1sIcon;
                } else if (item.markerImage === "ZoneA") {
                  markerIcon = img2sIcon;
                } else if (item.markerImage === "ZoneB") {
                  markerIcon = img3sIcon;
                } else {
                  markerIcon = img1sIcon;
                }
                return (
                  <Marker key={index} position={[item.LAT, item.LON]} icon={markerIcon}>
                    <Popup>
                      <div className="popup-Alert">
                        <p>Type: {item.TYP == 1 ? <>Cloud to Ground</> : <>Cloud to Cloud</>}</p>
                        <p>Current: {item.AMP} kA</p>
                        <p>Date: {moment.utc(item.DAT).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        <p>Latitude: {item.LAT}</p>
                        <p>Longitude: {item.LON}</p>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}

            {AllOrganization.map(([lat, lon, id, activeZoneEField, activeZoneR, activeZoneA, activeZoneB]) => (
              <CircleMarker
                key={uuidv4()}
                center={[lat, lon]}
                radius={9}
                maxScale={9}
                color="white"
                fillColor="red"
                fillOpacity={0.8}
              >
                <Popup className="">
                  <div className="popup-header">
                    Organization{" "}
                    <button
                      className="setting-btns"
                      style={{ position: "absolute", right: "20px" }}
                      onClick={() => settinOrganization(id)}
                    >
                      Setting
                    </button>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">Organization Name : </div>
                    <div className="popup-value">{id}</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">Latitude : </div>
                    <div className="popup-value">{lat}</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">Longitude : </div>
                    <div className="popup-value">{lon}</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">activeZoneR : </div>
                    <div className="popup-value">{activeZoneR / 1000} km</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">activeZoneA : </div>
                    <div className="popup-value">{activeZoneA / 1000} km</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">activeZoneB : </div>
                    <div className="popup-value">{activeZoneB / 1000} km</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">activeZoneEField : </div>
                    <div className="popup-value">{activeZoneEField / 1000} km</div>
                  </div>
                </Popup>
              </CircleMarker>
            ))}

            {EfieldLocation.message &&
              showEfield &&
              EfieldLocation.message.map((item) => (
                <CircleMarker
                  key={uuidv4()}
                  center={[item.lat, item.lon]}
                  radius={9}
                  maxScale={9}
                  color="white"
                  fillColor="#22c764"
                  fillOpacity={0.9}
                  pathOptions={{ zIndex: 1000 }}
                >
                  <Popup className="">
                    <div className="popup-header">E-Field Sensor </div>
                    <div className="popup-content">
                      <div className="popup-label">WarningPoint Id : </div>
                      <div className="popup-value">{item.warningPointId}</div>
                    </div>
                    <div className="popup-content">
                      <div className="popup-label">WarningPoint Name : </div>
                      <div className="popup-value">{item.warningPointName}</div>
                    </div>
                    <div className="popup-content">
                      <div className="popup-label">Latitude : </div>
                      <div className="popup-value">{item.lat}</div>
                    </div>
                    <div className="popup-content">
                      <div className="popup-label">Longitude : </div>
                      <div className="popup-value">{item.lon}</div>
                    </div>
                  </Popup>
                </CircleMarker>
              ))}
            {showEfield && EfieldMarker && <Marker position={[EfieldMarker.lat, EfieldMarker.lon]}></Marker>}
            {selectedLocation.lat ? <Marker position={[selectedLocation.lat, selectedLocation.lon]}></Marker> : <></>}
          </MapContainer>
          <div className="optionBoxAdmin">
            {/* <button onClick={handleSoundMute} style={{ border: "none", borderRadius: "15px", width: "50px", height: "25px", cursor: "pointer", marginBottom: "10px" }}>
              {soundWarning ? (<img src="./image/volume.png" alt="volume" width="22px" />) : (
                <img src="./image/mute.png" alt="volume" width="22px" />
              )}
            </button> */}
            <div className="switch-container" style={{ marginBottom: "10px" }}>
              <div>
                <button
                  style={{ border: "none", borderRadius: "15px", width: "50px", height: "25px", marginBottom: "10px" }}
                >
                  <img src="./image/sensor.png" alt="sensor" width="25px" />
                </button>
              </div>

              <label className="switch" style={{ marginLeft: "5px" }}>
                <input
                  type="checkbox"
                  onClick={(e) => {
                    setShowEfield(!showEfield);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div>
              <button
                style={{ border: "none", borderRadius: "15px", width: "50px", height: "25px", marginBottom: "10px" }}
              >
                <img src="./image/zeus.png" alt="sensor" width="20px" />
              </button>
            </div>
            <label className="switch" style={{ marginLeft: "5px" }}>
              <input
                type="checkbox"
                onClick={(e) => {
                  setShowLighning(!showLighning);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="goOnOrganization">
            <FormControl>
              <InputLabel sx={{ top: "-12px" }}>Location</InputLabel>
              <Select
                value={(selectedLocation && selectedLocation.nameLocation) || ""}
                defaultValue={""}
                endAdornment={
                  selectedLocation && (
                    <InputAdornment position="end">
                      <IconButton aria-label="Clear" onClick={handleClear}>
                        <ClearIcon style={{ width: "20px" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                sx={{
                  height: "30px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
                onChange={handleLocationChange}
                style={{ fontSize: "16px", width: "350px" }}
              >
                {AllOrganization.map(
                  ([lat, lon, id, activeZoneEField, activeZoneR, activeZoneA, activeZoneB, nameLocation, index]) => (
                    <MenuItem value={nameLocation} key={uuidv4()}>
                      {nameLocation}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>
          <div className="item-chart">
            <div className="Detail-block-chart" align="center">
              {
                <span className="title-block">
                  Electric Field (V/m) Warning Point Name:&nbsp;
                  <Select
                    value={Efield || ""}
                    onChange={(e) => setEfield(e.target.value)}
                    style={{ height: "18px", fontSize: "12px", background: "white" }}
                  >
                    {AllEfield_admin.status === 200 &&
                      AllEfield_admin.message.map((item, index) => (
                        <MenuItem value={item.warningPointId} key={uuidv4()}>
                          {item.warningPointName ? item.warningPointName : ""}
                        </MenuItem>
                      ))}
                  </Select>
                  &nbsp;
                  {showEfield && (
                    <span style={{ cursor: "pointer" }} onClick={handleLocationChangeEfield}>
                      <img src="/image/gps.png" alt="efield" width="20px" style={{ position: "absolute" }} />
                    </span>
                  )}
                </span>
              }
              <div className="white-block-chart">
                {Efield && (
                  // <ChartEField dataOrg={Efield} />
                  <ChartEField2 dataOrg={Efield} key={Math.random()} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`mapView-container ${showSidebars}`}>
        <div className="route-map-backgorund ">
          <MapContainer
            center={[13.736717, 100.523186]}
            zoom={5}
            // maxBounds={asiaBounds}
            maxBoundsViscosity={1.0}
            maxZoom={18}
            zoomControl={false}
            minZoom={4}
            style={{ height: "100%", width: "100%" }}
          >
            {/* <MapWrapper /> */}
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
            />
          </MapContainer>
        </div>
      </div>
    );
  }
}
