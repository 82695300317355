import React, { useEffect, useRef, useState } from "react";
import { CanvasJSChart } from "canvasjs-react-charts";
import moment from "moment";

const { convertSecondsToHoursMinutes, convertSecondsToHoursMinutesOne } = require("./convert");

const LineGraph = ({ dataOrg }) => {
  const chartRef = useRef(null);
  const [dataPoints, setDataPoints] = useState([]);

  useEffect(() => {
    fetchDataPoints();
    const intervalId = setInterval(fetchDataPoints, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [dataOrg]);

  const fetchDataPoints = async () => {
    try {
      const currentDate = moment().utc();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const warningPointId = dataOrg;
      const response = await fetch(
        `http://103.28.240.197:8080/DataServer/data/electric/historyData?warningPointId=${warningPointId}&entityQuery.realTimeLower=${formattedDate}`
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        const averagedDataPoints = convertSecondsToHoursMinutes(data);
        setDataPoints(averagedDataPoints);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const filteredData = dataPoints.filter((value) => value.y > 1 || value.y < -1);
  const maxAbsValue = Math.max(...filteredData.map((point) => Math.abs(point.y)));
  const pointWithMaxAbs = filteredData.find((point) => Math.abs(point.y) === maxAbsValue);
  const convertAxisX = (e) => {
    return convertSecondsToHoursMinutesOne(e.value);
  };

  const formatTooltipContent = (e) => {
    const date = convertSecondsToHoursMinutesOne(e.entries[0].dataPoint.x);
    const value = e.entries[0].dataPoint.y;
    return `${date} <br/> ${value} kV/m`;
  };

  const options = {
    responsive: true,
    zoomEnabled: true,
    axisY: {
      valueFormatString: "0",
      minimum: pointWithMaxAbs ? -Math.ceil(Math.abs(pointWithMaxAbs.y)) : -1,
      maximum: pointWithMaxAbs ? Math.ceil(Math.abs(pointWithMaxAbs.y)) : 1,

      // minimum: -2,
      // maximum: 2,
      thickness: 1,
      interval: pointWithMaxAbs ? Math.ceil(Math.abs(pointWithMaxAbs.y)) : 1,
      gridColor: "rgba(0, 0, 0, 0.0)",
      // Set the interval to 0.1
      intervalType: "number",
      labelFormatter: (e) => {
        return `${e.value} kV/m`; // Add the unit to the label
      },
    },
    axisX: {
      minimum: 0,
      labelFormatter: convertAxisX,
    },
    toolTip: { content: formatTooltipContent },
    data: [
      {
        type: "line",
        dataPoints: dataPoints,
        lineColor: "#d71d24", // Change the line color to 'red'
        lineThickness: 2, // Increase the line thickness to create a smooth line
        markerType: "none", // Remove the data points
      },
    ],
  };
  const containerProps = {
    height: "calc(100% - 8px)",
  };
  return <CanvasJSChart containerProps={containerProps} options={options} ref={chartRef} />;
};

export default LineGraph;
