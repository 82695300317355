import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Circle, Popup, CircleMarker, Marker, useMapEvents, useMap } from "react-leaflet";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  Snackbar,
} from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { LatLngBounds } from "leaflet";
import L from "leaflet";
import AirIcon from "@mui/icons-material/Air";
import Cookies from "js-cookie";
import ChartEField from "../../MainPage/ChartEField";
import ChartEField2 from "../../MainPage/ChartEField2";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const config = require("./../../../configs/app");

export default function Map({ dataOrg }) {
  const keyName = Cookies.get("pathName");
  const [reload, setreload] = useState(0);
  const [mapCenter, setMapCenter] = useState([13.736717, 100.523186]);
  const [efieldData, setEfieldData] = useState(null);
  const [weather, setWeather] = useState(null);
  const [pm2_5, setPM2_5] = useState(null);
  const weatherId = "43df35651f83242ac275ea501eb96ca9";
  const [simMfieldStatus, setSimMfieldStatus] = useState(false);
  const [waitSim, setWaitSim] = useState(false);
  const [simEfield, setSimEfield] = useState({ level: 1 });
  const [markers, setMarkers] = useState([]);
  const [MfieldWarning, setMFieldWarning] = useState([]);
  const [warningData, setWarningData] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [configMfield, setConfigMfield] = useState([]);
  const [alertDataUse, setAlertDataUse] = useState("");
  const [simDistance, setSimDistance] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [currentLevel, setCurrentLevel] = useState([]);
  const markerLifetime = 5000;
  const [statusMessage, setStatusMessage] = useState("");
  const mapRef = useRef(null);
  const socketRef = useRef(null);
  const iconSim = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_RE.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img1sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_RE.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img2sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_OR.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img3sIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_GR.png",
    iconSize: [25, 25],
    iconAnchor: [15, 30],
  });

  const img1Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CCR.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img2Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CR.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img3Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CCO.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img4Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CO.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img5Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CCY.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const img6Icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/image/Lightning_icon/K_ICON_CY.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const asiaBounds = new LatLngBounds([0, 70], [60, 140]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  const handleMfieldChange = () => {
    setSimMfieldStatus(!simMfieldStatus);
  };

  const handleLevelChange = (e) => {
    setSimEfield((prevSimEfield) => ({
      ...prevSimEfield,
      level: e.target.value,
    }));
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        if (dataOrg && dataOrg.display) {
          const distance = calculateDistance(dataOrg.lat, dataOrg.lon, lat, lng);
          // if (distance < dataOrg.display / 1000) {
          handleMarkerAdded([lat, lng]);
          postSimM(lat, lng);
          // }
        }
      },
    });
    return null;
  };

  const postSimM = async (lat, lon) => {
    try {
      const mFieldData = { lat: lat.toFixed(6), lon: lon.toFixed(6) };
      const url = `${config.urlTestWarning}/api/v1/test/m-field/${keyName}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mFieldData),
      });
      const responseData = await response.json();
      if (responseData.status === 200) {
        setStatusMessage(`Simulation successful`);
      } else {
        setStatusMessage(responseData.message);
      }
    } catch (error) {
      setStatusMessage("Error Warning send");
    }
  };

  const handlePostEfield = async () => {
    try {
      setWaitSim(true);
      const url = `${config.urlTestWarning}/api/v1/test/e-field/${efieldData}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(simEfield),
      });
      const responseData = await response.json();

      if (responseData.status === 200) {
        setStatusMessage(`Post successful warning level ${simEfield.level}`);
      } else {
        setStatusMessage(responseData.message);
      }
    } catch (error) {
      setStatusMessage("Error Warning send");
    } finally {
      setWaitSim(false);
    }
  };

  const handleMarkerAdded = (newMarker) => {
    setMarkers([newMarker]);
    // setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
    const distance = calculateDistance(dataOrg.lat, dataOrg.lon, parseFloat(newMarker[0]), parseFloat(newMarker[1]));
    setSimDistance(distance.toFixed(0));
  };

  const fetchData = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/calculateMField`;
      const Apiform = {
        lat: dataOrg.lat,
        lon: dataOrg.lon,
        radius: dataOrg.display,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Apiform),
      });
      const result = await response.json();
      if (result.status === 200) {
        setMFieldWarning(result);
      }
    } catch (error) {}
  };

  const fetchConfig = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/warning-config/`;
      const response = await fetch(url, {
        method: "GET",
      });
      const result = await response.json();
      if (result.status === 200) {
        const configKeyName = result.message.filter((items) => items.keyName === dataOrg.keyName);
        // const configLevel = data.sort((a, b) => b.levelWarning - a.levelWarning);
        const distanceR = configKeyName.filter((items) => items.levelWarning === 3);
        const distanceA = configKeyName.filter((items) => items.levelWarning === 2);
        const distanceB = configKeyName.filter((items) => items.levelWarning === 1);
        const R = dataOrg.activeZoneR;
        const A = dataOrg.activeZoneA;
        const B = dataOrg.activeZoneB;

        // console.log(distanceR[0].activeZoneStart)
        // console.log(distanceR[0].activeZoneEnd)
        // console.log(A)
        // console.log(B)
        // console.log(dataOrg);
        setConfigMfield(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataOrg) {
      fetchConfig();
    }
  }, [dataOrg]);

  const currentTime = moment.utc();
  function isWithinLast15Minutes(date) {
    const diffInMilliseconds = currentTime.diff(moment.utc(date));
    return diffInMilliseconds <= 15 * 60 * 1000;
  }

  function getTimeRange(date) {
    const diffInMilliseconds = currentTime.diff(moment.utc(date));
    if (diffInMilliseconds <= 5 * 60 * 1000) {
      return "ZoneR";
    } else if (diffInMilliseconds <= 10 * 60 * 1000) {
      return "ZoneA";
    } else if (diffInMilliseconds <= 15 * 60 * 1000) {
      return "ZoneB";
    }
    return "ZoneC";
  }

  const filteredDataWithMarkers = Object.keys(MfieldWarning.message || {})
    .filter((key) => isWithinLast15Minutes(MfieldWarning.message[key].date))
    .map((key) => {
      const entry = MfieldWarning.message[key];
      entry.markerImage = getTimeRange(entry.date);
      return entry;
    });

  let nearestData = null;
  let nearestDistance = Infinity;
  for (const key in filteredDataWithMarkers) {
    if (filteredDataWithMarkers.hasOwnProperty(key)) {
      const point = filteredDataWithMarkers[key];
      const distance = calculateDistance(dataOrg.lat, dataOrg.lon, parseFloat(point.lat), parseFloat(point.lon));
      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearestData = point;
      }
    }
  }

  const handleCloseStatusMessage = () => {
    setStatusMessage("");
  };

  async function fetchWeather() {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${dataOrg.lat}&lon=${dataOrg.lon}&appid=${weatherId}`
      );
      const weatherData = await response.json();
      return weatherData;
    } catch (error) {
      return null;
    }
  }

  const getStatusAlert = async () => {
    try {
      const url = `${config.urlLightningConfig}/api/v1/state-alert/keyName/${keyName}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      const result = await response.json();
      if (result.status === 200) {
        setAlertDataUse(result.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getStatusAlert();
    if (alertData[0]) {
      getStatusAlert();
    }
  }, [alertData]);

  async function fetchPm() {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/air_pollution?lat=${dataOrg.lat}&lon=${dataOrg.lon}&appid=${weatherId}`
      );
      const pmData = await response.json();
      return pmData.list[0].components.pm2_5;
    } catch (error) {
      return null;
    }
  }

  // useEffect(() => {
  //   if (markers.length > 0) {
  //     const firstMarkerTimeout = setTimeout(() => {
  //       setMarkers((prevMarkers) => prevMarkers.slice(1));
  //     }, markerLifetime);

  //     return () => clearTimeout(firstMarkerTimeout);
  //   }
  // }, [markers]);

  useEffect(() => {
    if (dataOrg.keyName) {
      fetchData();
      const intervalId = setInterval(fetchData, 60000);
      return () => clearInterval(intervalId);
    }
  }, [dataOrg]);

  useEffect(() => {
    async function fetchData() {
      if (dataOrg && dataOrg.keyName) {
        if (!weather) {
          const weatherData = await fetchWeather();
          setWeather(weatherData);
        }
        if (!pm2_5) {
          const pm2_5Data = await fetchPm();
          setPM2_5(pm2_5Data);
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const filteredDataWarning =
      warningData && warningData[0] && warningData.filter((item) => item.keyName === dataOrg.keyName);
    const highestLevelObject = filteredDataWarning
      ? filteredDataWarning.reduce((acc, obj) => {
          if (!acc || obj.level > acc.level) {
            return obj;
          }
          return acc;
        }, null)
      : null;
    const result = highestLevelObject ? [highestLevelObject] : [];
    let intervalId;
    if (warningData && result[0]) {
      setAlertData((prevAlerts) => {
        let updatedAlerts = [...prevAlerts, result[0]];
        let updatedData = updatedAlerts.map((item) => ({ ...item, time: 15 }));
        return updatedData;
      });
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [warningData]);

  const comfirmAlert = () => {
    const url = `${config.urlLightningConfig}/api/v1/state-alert/closePopup/${keyName}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        getStatusAlert();
        // console.log('Data posted successfully:', responseData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    if (alertData[0] && !currentLevel[0]) {
      setCurrentLevel([alertData[0]]);
    } else if (alertData[0] && currentLevel[0] && alertData[0].levelWarning >= currentLevel[0].levelWarning) {
      setCurrentLevel([alertData[0]]);
    }
  }, [alertData]);

  useEffect(() => {
    const updateDataWithTimeDifference = () => {
      const currentTime = moment.utc();
      const updatedData = currentLevel.map((entry) => {
        const entryTime = moment.utc(entry.date);
        // console.log(`SS ${entryTime}`)
        // console.log(`S1SS ${currentTime}`)

        const timeDifference = entryTime - currentTime;

        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        // console.log(`S1SS ${minutesDifference}`)
        if (minutesDifference > 5) {
          return {
            ...entry,
            levelWarning: nearestData
              ? (nearestData.markerImage === "ZoneR" && 3) ||
                (nearestData.markerImage === "ZoneA" && 2) ||
                (nearestData.markerImage === "ZoneB" && 1)
              : null,
          };
        }
        return {
          ...entry,
          timeDifference: minutesDifference,
        };
      });

      // console.log(updatedData);
    };

    updateDataWithTimeDifference();
    const interval = setInterval(updateDataWithTimeDifference, 60000);
    return () => clearInterval(interval);
  }, [currentLevel]);
  // console.log(currentLevel);
  // const closeAlertAfterDelay = (index, time) => {
  //   const timeoutId = setTimeout(() => {
  //     handleAlertClose(index);
  //   }, time);
  //   return timeoutId;
  // };

  useEffect(() => {
    if (alertData.length > 1) {
      setAlertData((prevAlerts) => {
        const updatedAlerts = [prevAlerts[prevAlerts.length - 1]];
        return updatedAlerts;
      });
    }
  }, [alertData]);

  // useEffect(() => {
  //   timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
  //   const newTimeoutIds = alertData.map((alert, index) => {
  //     if (alert.time) {
  //       return closeAlertAfterDelay(index, 1000 * alert.time);
  //     }
  //     return null;
  //   });
  //   setTimeoutIds(newTimeoutIds);
  //   return () => {
  //     newTimeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
  //   };
  // }, [alertData]);

  const handleAlertClose = (index) => {
    setAlertData((prevAlerts) => {
      const updatedAlerts = [...prevAlerts];
      updatedAlerts.splice(index, 1);
      return updatedAlerts;
    });
  };

  const connectWebSocket = () => {
    socketRef.current = new WebSocket(`${config.urlWebsocket}/websocket`);
    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setWarningData(data);
    };
    socketRef.current.onclose = () => {
      setTimeout(connectWebSocket, 1000);
    };
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  // useEffect(() => {
  //   socketRef.current = new WebSocket(`${config.urlWebsocket}`);
  //   socketRef.current.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     setWarningData(data);
  //   };

  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const setPosition = () => {
      const centerOrg = [dataOrg.lat, dataOrg.lon];
      const targetLocation = { lat: centerOrg[0], lon: centerOrg[1] };
      if (mapRef.current) {
        mapRef.current.setView(targetLocation, 11);
        setreload(1);
      }
    };
    if (reload === 0 && dataOrg.keyName) {
      setPosition();
    }
  }, [reload, dataOrg]);

  if (dataOrg && dataOrg.keyName) {
    const theData = dataOrg;
    const centerOrg = [dataOrg.lat, dataOrg.lon];
    if (!efieldData && dataOrg.EFieldSensors[0]) {
      setEfieldData(dataOrg.EFieldSensors[0].warningPointId);
    }
    return (
      <React.Fragment>
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <MapContainer
            center={centerOrg}
            zoom={11}
            style={{ height: "100%", width: "100%" }}
            zoomControl={false}
            ref={mapRef}
            // maxBounds={asiaBounds}
            maxBoundsViscosity={1.0}
            maxZoom={18}
            minZoom={4}
          >
            {simMfieldStatus && <MapClickHandler />}
            {simMfieldStatus && (
              <div
                className="map-overlay"
                style={{
                  zIndex: 1000,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.15)",
                  pointerEvents: "none",
                }}
              ></div>
            )}
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {filteredDataWithMarkers &&
              !simMfieldStatus &&
              filteredDataWithMarkers.map((item, index) => {
                let markerIcon = "";
                // if (item.markerImage === "ZoneR" && item.type == 2) {
                //   markerIcon = img1Icon;
                // }
                // else if (item.markerImage === "ZoneR" && item.type == 1) {
                //   markerIcon = img2Icon;
                // }
                // else if (item.markerImage === "ZoneA" && item.type == 2) {
                //   markerIcon = img3Icon;
                // }
                // else if (item.markerImage === "ZoneA" && item.type == 1) {
                //   markerIcon = img4Icon;
                // }
                // else if (item.markerImage === "ZoneB" && item.type == 2) {
                //   markerIcon = img5Icon;
                // }
                // else if (item.markerImage === "ZoneB" && item.type == 1) {
                //   markerIcon = img6Icon;
                // }

                if (item.markerImage === "ZoneR") {
                  markerIcon = img1sIcon;
                } else if (item.markerImage === "ZoneA") {
                  markerIcon = img2sIcon;
                } else if (item.markerImage === "ZoneB") {
                  markerIcon = img3sIcon;
                } else {
                  markerIcon = img1sIcon;
                }
                return (
                  <Marker key={index} position={[item.lat, item.lon]} icon={markerIcon}>
                    <Popup>
                      <div className="popup-Alert">
                        <p>Type: {item.type == 1 ? <>Cloud to Ground</> : <>Cloud to Cloud</>}</p>
                        <p>Current: {item.amp} kA</p>
                        <p>Date: {moment.utc(item.date).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        <p>Latitude: {item.lat}</p>
                        <p>Longitude: {item.lon}</p>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}

            <CircleMarker center={centerOrg} radius={9} maxScale={9} color="white" fillColor="red" fillOpacity={0.8}>
              {!simMfieldStatus && (
                <Popup className="">
                  <div className="popup-header">Organization</div>
                  <div className="popup-content">
                    <div className="popup-label">Organization Name : </div>
                    <div className="popup-value">{dataOrg.keyName}</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">Latitude : </div>
                    <div className="popup-value">{mapCenter[0]}</div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-label">Longitude : </div>
                    <div className="popup-value">{mapCenter[1]}</div>
                  </div>
                </Popup>
              )}
              {dataOrg.mapType !== 2 && (
                <>
                  <Circle
                    center={centerOrg}
                    radius={dataOrg.display}
                    fill={true}
                    fillOpacity={0}
                    pathOptions={{
                      color: "rgb(155, 155, 155)",
                    }}
                  >
                    {!simMfieldStatus && (
                      <Popup className="">
                        <div className="popup-header">display Distance</div>
                        <div className="popup-content">
                          <div className="popup-value">{dataOrg.display / 1000} km</div>
                        </div>
                      </Popup>
                    )}
                  </Circle>
                </>
              )}

              {dataOrg.mapType !== 2 && (
                <>
                  <Circle
                    center={centerOrg}
                    radius={dataOrg.activeZoneR}
                    fill={true}
                    fillOpacity={0.1}
                    pathOptions={{
                      fillColor: "none",
                      color: "red",
                      transition: "transform 1s ease-in-out",
                      fillColor: "#ff0000",
                    }}
                  >
                    {!simMfieldStatus && (
                      <Popup className="">
                        <div className="popup-header">activeZoneR Distance</div>
                        <div className="popup-content">
                          <div className="popup-value">{dataOrg.activeZoneR / 1000} km</div>
                        </div>
                      </Popup>
                    )}
                    <Circle
                      center={centerOrg}
                      radius={dataOrg.activeZoneA}
                      fill={true}
                      fillOpacity={0}
                      pathOptions={{
                        color: "rgb(155, 155, 155)",
                      }}
                    >
                      {!simMfieldStatus && (
                        <Popup className="">
                          <div className="popup-header">activeZoneA Distance</div>
                          <div className="popup-content">
                            <div className="popup-value">{dataOrg.activeZoneA / 1000} km</div>
                          </div>
                        </Popup>
                      )}
                      <Circle
                        center={centerOrg}
                        radius={dataOrg.activeZoneB}
                        fill={true}
                        fillOpacity={0}
                        pathOptions={{
                          color: "rgb(155, 155, 155)",
                        }}
                      >
                        {!simMfieldStatus && (
                          <Popup className="">
                            <div className="popup-header">activeZoneB Distance</div>
                            <div className="popup-content">
                              <div className="popup-value">{dataOrg.activeZoneB / 1000} km</div>
                            </div>
                          </Popup>
                        )}
                      </Circle>
                    </Circle>
                  </Circle>
                </>
              )}
              {dataOrg.mapType !== 1 && (
                <Circle
                  center={centerOrg}
                  radius={dataOrg.activeZoneEField}
                  fill={true}
                  fillOpacity={0}
                  pathOptions={{
                    color: "rgb(155, 155, 155)",
                  }}
                >
                  {!simMfieldStatus && (
                    <Popup className="">
                      <div className="popup-header">E-Field Distance</div>
                      <div className="popup-content">
                        <div className="popup-value">{dataOrg.activeZoneEField / 1000} km</div>
                      </div>
                    </Popup>
                  )}
                </Circle>
              )}
              {markers &&
                simMfieldStatus &&
                markers[0] &&
                markers.map((item, index) => (
                  <Marker
                    key={index}
                    position={item}
                    icon={iconSim}
                    pathOptions={{
                      zIndex: 1200,
                    }}
                  ></Marker>
                ))}
            </CircleMarker>
          </MapContainer>

          {dataOrg.mapType !== 2 && (
            <>
              <Card
                variant="contained"
                sx={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  bgcolor: "rgba(255, 255, 255, 0.562);",
                  color: "black",
                  zIndex: 1000,
                  width: "180px",
                  height: "120px",
                  borderRadius: 2.5,
                  opacity: 0.9,
                }}
              >
                <CardContent
                  sx={{
                    bgcolor: "rgba(240, 240, 240, 0.712)",
                    height: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "600" }}>
                    Zone
                  </Typography>
                </CardContent>
                <Box color="black" sx={{ padding: 1, height: "100%" }}>
                  <Box
                    sx={{
                      bgcolor: "white",
                      p: 0.5,
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 2,
                    }}
                  >
                    <>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "red",
                        }}
                      >
                        <Typography variant="caption">
                          Zone: R 0-{(dataOrg.activeZoneR / 1000).toFixed(0)} km radius
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">
                          Zone: A {(dataOrg.activeZoneR / 1000).toFixed(0)}-{(dataOrg.activeZoneA / 1000).toFixed(0)} km
                          radius
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">
                          Zone: B {(dataOrg.activeZoneA / 1000).toFixed(0)}-{(dataOrg.activeZoneB / 1000).toFixed(0)} km
                          radius
                        </Typography>
                      </Grid>
                    </>
                  </Box>
                </Box>
              </Card>
              <Card
                variant="contained"
                sx={{
                  position: "absolute",
                  top: "140px",
                  left: "10px",
                  bgcolor: "rgba(255, 255, 255, 0.562);",
                  color: "black",
                  zIndex: 1001,
                  width: "180px",
                  height: "120px",
                  borderRadius: 2.5,
                  opacity: 0.9,
                }}
              >
                <CardContent
                  sx={{
                    bgcolor: "rgba(240, 240, 240, 0.712)",
                    height: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "600" }}>
                    Historical Stroke
                  </Typography>
                </CardContent>
                <Box color="black" sx={{ padding: 1, height: "100%" }}>
                  <Box
                    sx={{
                      bgcolor: "white",
                      p: 0.5,
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_RE.png"}
                        alt="Tunder RE"
                        width="15"
                        height="15"
                      />
                      <Typography variant="caption">&nbsp; 0-5 Minutes</Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_OR.png"}
                        alt="Tunder RE"
                        width="15"
                        height="15"
                      />
                      <Typography variant="caption">&nbsp; 5-10 Minutes</Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/image/Lightning_icon/Tunder_GR.png"}
                        alt="Tunder RE"
                        width="15"
                        height="15"
                      />
                      <Typography variant="caption">&nbsp; 10-15 Minutes</Typography>
                    </Grid>
                  </Box>
                </Box>
              </Card>
            </>
          )}

          {weather && pm2_5 && (
            <Card
              variant="contained"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                bgcolor: "rgba(255, 255, 255, 0.562);",
                color: "black",
                zIndex: 1101,
                width: 400,
                height: 160,
                borderRadius: 2.5,
                opacity: 0.9,
              }}
            >
              <CardContent
                sx={{
                  bgcolor: "rgba(240, 240, 240, 0.712)",
                  height: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Weather
                </Typography>
              </CardContent>
              <Grid container>
                <Grid item xs={5} p={1}>
                  <Box
                    sx={{
                      bgcolor: "rgba(255, 255, 255, 0.762)",
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 4,
                      color: "black",
                      p: 1,
                      height: "90px",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                        alt="Weather"
                        width="40px"
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        my: 1,
                      }}
                    >
                      <Typography variant="caption">{(weather.main.temp - 273.15).toFixed(2)} °C </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FmdGoodIcon fontSize="small" color="error" />
                      <Typography variant="caption">{weather.name}</Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={7} paddingTop={1} paddingRight={1}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    sx={{
                      bgcolor: "rgba(255, 255, 255, 0.762)",
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 4,
                      color: "black",
                      height: 105,
                    }}
                  >
                    <Grid container display="flex" alignItems="center">
                      <Grid
                        item
                        xs={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <img src={process.env.PUBLIC_URL + "/image/Hu_test.png"} alt="Humidity" width="25px" />
                        <Typography variant="caption">Humidity</Typography>
                        <Typography variant="caption">{weather.main.humidity}%</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <AirIcon fontSize="large" sx={{ color: "#318CE7" }} />
                        <Typography variant="caption">Wind Speed</Typography>
                        <Typography variant="caption">{weather.wind.speed} m/s</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <img src={process.env.PUBLIC_URL + "/image/PM_test.png"} alt="status" width="30px" />
                        <Typography variant="caption">PM 2.5</Typography>
                        <Typography variant="caption">{pm2_5} ug/m3</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          )}
          <div
            style={{
              position: "absolute",
              top: "270px",
              left: "10px",
              zIndex: 1100,
            }}
          >
            <Card
              variant="contained"
              sx={{
                width: "180px",
                bgcolor: "rgba(255, 255, 255, 0.562);",
                color: "black",
                borderRadius: 2.5,
                opacity: 0.9,
              }}
            >
              <CardContent
                sx={{
                  bgcolor: "rgba(240, 240, 240, 0.712)",
                  height: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Simulation
                </Typography>
              </CardContent>

              {dataOrg.mapType !== 2 && (
                <Box color="black" sx={{ padding: 1, height: "100%" }}>
                  <Box
                    sx={{
                      bgcolor: "white",
                      p: 1.5,
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: "14px" }}>
                        Sim Mode{" "}
                        <Switch checked={simMfieldStatus ? true : false} onChange={handleMfieldChange}></Switch>
                      </Typography>
                    </Grid>
                  </Box>
                </Box>
              )}

              {dataOrg.mapType !== 1 && dataOrg.EFieldSensors[0] && simMfieldStatus && (
                <Box color="black" sx={{ padding: 1, height: "100%" }}>
                  <Box
                    sx={{
                      bgcolor: "white",
                      p: 1.5,
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: "14px" }}>
                        <InputLabel>Warning level</InputLabel>
                        <Select
                          value={simEfield.level}
                          sx={{ height: "25px", width: "100px" }}
                          onChange={handleLevelChange}
                        >
                          <MenuItem value={1}>level 1</MenuItem>
                          <MenuItem value={2}>level 2</MenuItem>
                          <MenuItem value={3}>level 3</MenuItem>
                        </Select>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: "14px" }}>
                        <Button
                          sx={{
                            height: "25px",
                            width: "100px",
                            marginTop: "10px",
                          }}
                          variant="contained"
                          color="secondary"
                          onClick={handlePostEfield}
                          disabled={waitSim ? true : false}
                        >
                          Send
                        </Button>
                      </Typography>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Card>
          </div>

          {
            (nearestDistance !== Infinity && dataOrg.mapType !== 2 && !simMfieldStatus && (
              <div className={`showDistance admin`} align="center">
                Lightning within {Math.round(nearestDistance)} km
              </div>
            )) ||
              (simMfieldStatus && simDistance && (
                <div className={`showDistance admin`} align="center">
                  Lightning within {simDistance} km
                </div>
              ))

            // <div
            //   className={`showDistance admin ${Math.round(nearestDistance) <= theData.activeZoneR / 1000 && !simMfieldStatus && "R"}
            //     ${Math.round(nearestDistance) <= theData.activeZoneA / 1000 &&
            //     Math.round(nearestDistance) > theData.activeZoneR / 1000 && !simMfieldStatus &&
            //     "A"
            //     }
            //     ${Math.round(nearestDistance) < theData.activeZoneB / 1000 && Math.round(nearestDistance) > theData.activeZoneA / 1000 && !simMfieldStatus &&
            //     "B"
            //     }`}
            //   align="center"
            // >
            //   Lightning within {Math.round(nearestDistance)} kilometers
            // </div>
            // ) || simDistance && (
            //   <div
            //     className={`showDistance admin ${simDistance <= theData.activeZoneR / 1000 && "R"}
            //     ${simDistance <= theData.activeZoneA / 1000 &&
            //       simDistance > theData.activeZoneR / 1000 &&
            //       "A"
            //       }
            //     ${simDistance < theData.activeZoneB / 1000 &&
            //       simDistance > theData.activeZoneA / 1000 &&
            //       "B"
            //       }`}
            //     align="center"
            //   >
            //     Lightning within {simDistance} kilometers
            //   </div>
            // )
          }

          <div
            style={{
              position: "absolute",
              top: "140px",
              left: "200px",
              zIndex: 1100,
            }}
          >
            <div className="Circle-info" align="center" style={{ zIndex: 1000 }}>
              <div
                className={`Circle-law  ${
                  alertDataUse && !simMfieldStatus
                    ? alertDataUse.level === 3 && "ActiveZoneR"
                    : alertDataUse && alertDataUse.level === 3 && "ActiveZoneR"
                }`}
              />
              <div
                className={`Circle-law  ${
                  alertDataUse && !simMfieldStatus
                    ? alertDataUse.level === 2 && "ActiveZoneA"
                    : alertDataUse && alertDataUse.level === 2 && "ActiveZoneA"
                }`}
              ></div>
              <div
                className={`Circle-law  ${
                  alertDataUse && !simMfieldStatus
                    ? alertDataUse.level === 1 && "ActiveZoneB"
                    : alertDataUse && alertDataUse.level === 1 && "ActiveZoneB"
                }`}
              ></div>
              <div
                className={`Circle-law  ${
                  alertDataUse && !simMfieldStatus
                    ? alertDataUse.level === 0 && "UnActiveZone"
                    : (alertDataUse && alertDataUse.level === 0 && "UnActiveZone") || (!alertDataUse && "UnActiveZone")
                }`}
              ></div>
            </div>
          </div>

          {/* <div
            style={{
              position: "absolute",
              top: "140px",
              left: "200px",
              zIndex: 1100,
            }}
          >
            <div className="Circle-info" align="center" style={{ zIndex: 1000 }}>
              <div
                className={`Circle-law  ${nearestDistance !== Infinity && !simMfieldStatus ? (
                  Math.round(nearestDistance) <= theData.activeZoneR / 1000 &&
                  Math.round(nearestDistance) < theData.activeZoneA / 1000 && "ActiveZoneR") :
                  simDistance && simMfieldStatus &&
                  simDistance <= theData.activeZoneR / 1000 &&
                  simDistance < theData.activeZoneA / 1000 && "ActiveZoneR"
                  }`}
              />
              <div
                className={`Circle-law   ${nearestDistance !== Infinity && !simMfieldStatus ? (
                  Math.round(nearestDistance) <= theData.activeZoneA / 1000 &&
                  Math.round(nearestDistance) > theData.activeZoneR / 1000 && "ActiveZoneA") :
                  simMfieldStatus &&
                  simDistance <= theData.activeZoneA / 1000 &&
                  simDistance > theData.activeZoneR / 1000 && "ActiveZoneA"

                  }`}
              ></div>
              <div
                className={`Circle-law  ${nearestDistance !== Infinity && !simMfieldStatus ? (
                  Math.round(nearestDistance) <= theData.activeZoneB / 1000 &&
                  Math.round(nearestDistance) > theData.activeZoneA / 1000 && "ActiveZoneB") :
                  simMfieldStatus &&
                  simDistance <= theData.activeZoneB / 1000 &&
                  simDistance > theData.activeZoneA / 1000 && "ActiveZoneB"
                  }`}
              ></div>
              <div
                className={`Circle-law ${!simMfieldStatus ? (Math.round(nearestDistance) > (theData.activeZoneB / 1000) || nearestDistance === Infinity) && "UnActiveZone"
                  : simDistance ? (simDistance > theData.activeZoneB / 1000) && "UnActiveZone" : "UnActiveZone"}`}
              ></div>
            </div>
          </div> */}
          {alertDataUse && (
            <>
              {!alertDataUse.closePopup && (
                <div
                  style={{
                    position: "absolute",
                    top: "180px",
                    right: "10px",
                    width: "380px",
                    zIndex: 1100,
                  }}
                >
                  <div
                    className={`AlertAdmin ${alertDataUse.level == 1 && "B"} ${alertDataUse.level == 2 && "A"} ${
                      alertDataUse.level == 3 && "R"
                    } ${alertDataUse.level == 0 && "Safe"}`}
                    align="center"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `${alertDataUse.level !== 0 ? "/image/warning.png" : "/image/shield.png"}`
                      }
                      alt="alert"
                      width="35px"
                    />
                    <div style={{ fontWeight: "bold" }}>{alertDataUse.level !== 0 && "WARNING LIGHTNING"}</div>
                    <div
                      style={{
                        marginBottom: "10px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      {(alertDataUse.level === 3 && (
                        <>
                          Now have a lightning discharge within a radius of less than {theData.activeZoneR / 1000}{" "}
                          kilometer
                        </>
                      )) ||
                        (alertDataUse.level === 2 && "Now have a lightning discharge level 2.") ||
                        (alertDataUse.level === 1 && "Now have a lightning discharge level 1.") ||
                        (alertDataUse.level === 0 && "Now the situation is safe.")}
                    </div>
                    <button className="close-btn-alert" onClick={comfirmAlert}>
                      CLOSE
                    </button>
                  </div>

                  {/* <div
                className={`AlertAdmin ${alertData[0].levelWarning == 1 && "B"} ${alertData[0].levelWarning == 2 && "A"
                  } ${alertData[0].levelWarning == 3 && "R"}`}
                align="center"
              >
            {/*        <img src={process.env.PUBLIC_URL + "/image/warning.png"} alt="alert" width="35px" />
                <div style={{ fontWeight: "bold" }}> WARNING LIGHTNING</div>
                <div
                  style={{
                    marginBottom: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  {(alertData[0].levelWarning === 3 && (
                    <>
                      Now have a lightning discharge within a radius of less than {theData.activeZoneR / 1000}{" "}
                      kilometers.
                    </>
                  ))
                    || (alertData[0].levelWarning === 2 && "Now have a lightning discharge level 2.")
                    || (alertData[0].levelWarning === 1 && "Now have a lightning discharge level 1.")}
                </div> 
                <button className="close-btn-alert" onClick={handleAlertClose}>
                  CLOSE
                </button>
              </div>*/}
                </div>
              )}{" "}
            </>
          )}

          {/* {dataOrg.mapType !== 1 && dataOrg.EFieldSensors[0] && <Card
              variant="contained"
              sx={{
                marginTop: "10px",
                bgcolor: "rgba(255, 255, 255, 0.562);",
                color: "black",
                borderRadius: 2.5,

                opacity: 0.9,
              }}
            >
              <CardContent
                sx={{
                  bgcolor: "rgba(240, 240, 240, 0.712)",
                  height: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "600" }} >E-Field Simulation</Typography>
              </CardContent>
              <Box color="black" sx={{ padding: 1, height: "100%" }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    p: 1.5,
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: 2,
                  }}
                >
                  <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="caption" sx={{ fontSize: "14px" }} >
                      <InputLabel>Warning level</InputLabel>
                      <Select value={simEfield.level} sx={{ height: "25px", width: "100px" }} onChange={handleLevelChange}>
                        <MenuItem option value={1}>level 1</MenuItem>
                        <MenuItem option value={2}>level 2</MenuItem>
                        <MenuItem option value={3}>level 3</MenuItem>
                      </Select>
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="caption" sx={{ fontSize: "14px" }} >
                      <Button sx={{ height: "25px", width: "100px", marginTop: "10px" }} variant="contained" color="secondary" onClick={handlePostEfield}
                        disabled={waitSim ? true : false}
                      >Send</Button>
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Card>} */}

          {dataOrg.mapType !== 1 && dataOrg.EFieldSensors[0] && (
            <div className="item-chart2">
              <div className="Detail-block-chart" align="center">
                {
                  <span className="title-block">
                    Electric Field (V/m) Warning Point Name:&nbsp;
                    <Select
                      value={efieldData || ""}
                      onChange={(e) => setEfieldData(e.target.value)}
                      style={{
                        height: "18px",
                        fontSize: "12px",
                        background: "white",
                      }}
                    >
                      {dataOrg.mapType !== 1 &&
                        dataOrg.EFieldSensors.map((item, index) => (
                          <MenuItem value={item.warningPointId} key={uuidv4()}>
                            {item.warningPointName ? item.warningPointName : ""}
                          </MenuItem>
                        ))}
                    </Select>
                  </span>
                }
                <div className="white-block-chart">
                  {efieldData && (
                    // <ChartEField dataOrg={efieldData} />
                    <ChartEField2 dataOrg={efieldData} />
                  )}
                </div>
              </div>
            </div>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={statusMessage !== ""}
            onClose={handleCloseStatusMessage}
            autoHideDuration={3000}
            message={statusMessage}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <MapContainer
            key={uuidv4()}
            zoom={8}
            style={{ height: "100%", width: "100%" }}
            zoomControl={false}
            center={mapCenter}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </MapContainer>
        </div>
      </React.Fragment>
    );
  }
}
